import { useEffect, useState } from "react";

const Cursor = ({ info }) => {
    const [pos, setPos] = useState({x:0, y:0});

    useEffect(() => {
        const updateCursor = (e) => {
            setPos({x: e.clientX+15, y:e.clientY+15});
        }

        document.addEventListener('mousemove', updateCursor);
        return () => {
            document.removeEventListener('mousemove', updateCursor);
        }
    }, []);

    if(info === null){
        return(
            <div className='cursor' style={{left: pos.x, top: pos.y}}/>
            )
        }
        
        else{
            return(
                <div className='active-cursor' style={{left: pos.x, top: pos.y}}>
                    <p>{info}</p>
                </div>
        )
    }
}

export default Cursor;