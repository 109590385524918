import CircleAnim from "./HeroCircleAnim"
import { analytics } from "../Firebase";
import { logEvent } from "firebase/analytics";

const Home = ({ cursorMsg }) => {
    const publicUrl = process.env.PUBLIC_URL;

    const updateCursor = (e, i, enter) => {
        const msgArr = [
            null,
            'The "maneki-neko" in Japan symbolizes good luck and prosperity, often displayed to attract positive fortune.'
        ]
        
        if(enter){
            cursorMsg(msgArr[i]);
            logEvent(analytics, 'cursor_guide', {
                hovered: 'manekineko'
            });
        }
        else{
            cursorMsg(null);
        }

    }

    return(
        <div className='page-section home'>
            <div className='img-row'>
                <img src={publicUrl+'/box.png'} alt='gradientbox'/>
            </div>
            <div className='img-row'>
                <CircleAnim/>
            </div>
            <div className='img-row'>
                <img id='makineko' src={publicUrl+'/makineko.png'}onMouseEnter={(e) => updateCursor(e, 1, true)} onMouseLeave={() => updateCursor(null, 1, false)} alt='makineko'/>
            </div>
            <h1>ECLIPSEPIXEL</h1>
            <img id='herojp' src={publicUrl+'/herojp.png'} alt='開発者'/>
            <div className='hero-text-block'>
                <h1>JOIN THE NEW AGE OF THE WEB</h1>
                <p>EclipsePixel is here to guide you from start to finish on getting your website ready for the eyes of the world.  From wireframing and design all the way through hosting and domain configuration, get in touch with us today to get started!</p>
            </div>
        </div>
    )
}

export default Home;