import emailjs from 'emailjs-com';
import { useState } from 'react';
import { analytics } from '../Firebase';
import { logEvent } from 'firebase/analytics';

const Contact = () => {

    const publicUrl = process.env.PUBLIC_URL;
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    })

    const FormHandler = (e) => {
        e.preventDefault();
        const serviceId = 'service_6qu2h58';
        const templateId = 'template_mnj5myc';
        const userId = 'M0qjSu_kVW2st71pt';
        logEvent(analytics, 'form_submission', {
            formName: 'ContactForm'
        });

        emailjs.send(serviceId, templateId, formData, userId)
        .then((response) => {
            alert('Form Submitted Successfully', response)
            setFormData({
                name: '',
                phone: '',
                email: '',
                message: ''
            });
        })
        .catch((error) => {
            alert('An Error Has Ocurred', error);
        });
    }

    return(
        <div className='page-section contact'>
            <h1>ECLIPSEPIXEL</h1>
            <img src={publicUrl+'/inu.png'}/>
            <div className='contact-content'>
                <form onSubmit={FormHandler}>
                    <input type='text' placeholder='Your Name' value={formData.name} onChange={(e) => setFormData({...formData, name:e.target.value})}/>
                    <input type='number' placeholder='Phone Number'value={formData.phone} onChange={(e) => setFormData({...formData, phone:e.target.value})}/>
                    <input type='email' placeholder='Email Address'value={formData.email} onChange={(e) => setFormData({...formData, email:e.target.value})}/>
                    <textarea placeholder='How Can We Help?'value={formData.message} onChange={(e) => setFormData({...formData, message:e.target.value})}/>
                    <p>By submitting this form you acknowledge that you have read and understand our PRIVACY POLICY and TERMS OF SERVICE</p>
                    <input type='submit' value='SUBMIT'/>
                </form>
                <div className='contact-info'>
                    <div className='contact-info-item'>
                        <p>contact@eclipsepixel.com</p>
                        <h6>手紙</h6>
                    </div>
                    <div className='contact-info-item'>
                        <p>(346) 297 0403</p>
                        <h6>電話</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;