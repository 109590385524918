import { useState } from "react";

const MobileNav = ({ setPage }) => {

    const [menu, setMenu] = useState(false);
 
    const newPage = (e, pg) => {
        setPage(pg);
        let old = document.getElementsByClassName('m-nav-btn-active')[0];
        old.classList.remove('m-nav-btn-active');
        e.target.classList.add('m-nav-btn-active');
        toggleMenu();
    }

    const toggleMenu = () => {
        let bars = document.getElementsByClassName('bar');
        let menuE = document.getElementsByClassName('mobile-nav-menu');
        console.log(!menu)

        if(menu){
            bars[0].style.top = '0';
            bars[0].style.transform = 'rotate(0deg)';
            bars[1].style.width = '100%';
            bars[1].style.left = '0';
            bars[2].style.top = 'calc(100% - 4px)';
            bars[2].style.transform = 'rotate(0deg)';
            menuE[0].style.top = '-50vh'
        }
        else{
            bars[0].style.top = 'calc(50% - 2px)';
            bars[0].style.transform = 'rotate(45deg)';
            bars[1].style.width = '0px';
            bars[1].style.left = '50%';
            bars[2].style.top = 'calc(50% - 2px)';
            bars[2].style.transform = 'rotate(-45deg)';
            menuE[0].style.top = '0'
        }
        
        setMenu(!menu);
    }

    return(
        <div className='mobile-nav-container'>
            <div className='toggle' onClick={() => toggleMenu()}>
                <div className='bar' id='bar1'/>
                <div className='bar' id='bar2'/>
                <div className='bar' id='bar3'/>
            </div>
            <div className='mobile-nav-menu'>
                <button className='m-nav-btn-active' onClick={(e) => newPage(e, 'home')}>Home</button>
                <button onClick={(e) => newPage(e, 'about')}>About</button>
                <button onClick={(e) => newPage(e, 'services')}>Services</button>
                <button onClick={(e) => newPage(e, 'gallery')}>Gallery</button>
                <button onClick={(e) => newPage(e, 'contact')}>Contact</button>
            </div>
        </div>
    )
}

export default MobileNav;