import Marquee from "react-fast-marquee";

const Gallery = () => {
    const publicUrl = process.env.PUBLIC_URL;

    return(
        <div className='page-section gallery'>
            <Marquee speed={20}>
                <div className='gallery-card' style={{backgroundImage: 'url('+publicUrl+'/project1.png)'}}>
                    <div className='gallery-card-overlay'>
                        <h3>EVON POLISHING</h3>
                        <p>Creating a sophisticated, modern website for Evon Polishing while adhering to a one-page layout posed a key challenge. Balancing the desire for luxury visuals with the need for simplicity was crucial.</p>
                        <p>We opted for slightly muted red tones, infusing a sense of sophistication. Regal images and fonts were carefully chosen to add prestige. The streamlined one-page design, focused on simplicity, ensured a user-friendly experience. Ongoing collaboration with Evon Polishing allowed us to align the evolving vision, resulting in a digital space that not only met but exceeded expectations, positioning Evon Polishing as an industry leader.</p>
                    </div>
                </div>
                <div className='gallery-card' style={{backgroundImage: 'url('+publicUrl+'/geisha.jpg)'}}>
                    <div className='gallery-card-overlay'>
                    <h3>Project Title</h3>
                        <p>Project B</p>
                    </div>
                </div>
                <div className='gallery-card' style={{backgroundImage: 'url('+publicUrl+'/geisha.jpg)'}}>
                    <div className='gallery-card-overlay'>
                        <h3>Project Title</h3>
                        <p>Project C</p>
                    </div>
                </div>
                <div className='gallery-card' style={{backgroundImage: 'url('+publicUrl+'/geisha.jpg)'}}>
                    <div className='gallery-card-overlay'>
                        <h3>Project Title</h3>
                        <p>Project D</p>
                    </div>
                </div>
                <div className='gallery-card' style={{backgroundImage: 'url('+publicUrl+'/geisha.jpg)'}}>
                    <div className='gallery-card-overlay'>
                        <h3>Project Title</h3>
                        <p>Project E</p>
                    </div>
                </div>
                <div className='gallery-card' style={{backgroundImage: 'url('+publicUrl+'/geisha.jpg)'}}>
                    <div className='gallery-card-overlay'>
                        <h3>Project Title</h3>
                        <p>Project F</p>
                    </div>
                </div>
            </Marquee>
            <Marquee speed={90}>
                <img className='tech-img' src={publicUrl+'tech/angular.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/django.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/flask.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/javascript.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/oauth.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/python.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/stripe.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/angular.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/django.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/flask.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/javascript.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/oauth.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/python.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/stripe.png'} alt='tech-img'/>
            </Marquee>
            <Marquee speed={100}>
                <img className='tech-img' src={publicUrl+'tech/aws.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/express.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/gcloud.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/mysql.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/paypal.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/react.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/typescript.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/aws.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/express.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/gcloud.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/mysql.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/paypal.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/react.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/typescript.png'} alt='tech-img'/>
            </Marquee>
            <Marquee speed={110}>
                <img className='tech-img' src={publicUrl+'tech/css.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/firebase.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/html.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/node.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/php.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/rust.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/vue.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/css.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/firebase.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/html.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/node.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/php.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/rust.png'} alt='tech-img'/>
                <img className='tech-img' src={publicUrl+'tech/vue.png'} alt='tech-img'/>
            </Marquee>
        </div>
    );
}

export default Gallery