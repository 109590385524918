import { logEvent } from "firebase/analytics";
import { analytics } from "../Firebase";

const Nav = ({ setPage }) => {

    const newPage = (e, pg) => {
        setPage(pg);
        let old = document.getElementsByClassName('nav-btn-active')[0];
        old.classList.remove('nav-btn-active');
        e.target.classList.add('nav-btn-active');
        logEvent(analytics, 'page_visit', {
          pageVisited: pg
        })
    }

    return (
      <div className='nav'>
        <button className='nav-btn-active' onClick={(e) => newPage(e, 'home')}>HOME</button>
        <p>-</p>
        <button onClick={(e) => newPage(e, 'about')}>ABOUT</button>
        <p>-</p>
        <button onClick={(e) => newPage(e, 'services')}>SERVICES</button>
        <p>-</p>
        <button onClick={(e) => newPage(e, 'gallery')}>GALLERY</button>
        <p>-</p>
        <button onClick={(e) => newPage(e, 'contact')}>CONTACT</button>
      </div>
    );
  }
  
  export default Nav;
  