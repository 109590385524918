const Legal = ({type, close}) => {
    switch(type){
        case 'privacypolicy':
            return(
                <div className='legal'>
                    <div className='legal-wrapper'>
                        <button onClick={() => close()}>X</button>
                        <h1>Privacy Policy</h1>
                        <div className='legal-content'>
                            <h3>1. Introduction</h3>
                            <p>Welcome to Eclipse Pixel! This Privacy Policy governs the manner in which Eclipse Pixel collects, uses, maintains, and discloses information collected from users (each, a "User") of the eclipsepixel.com website ("Site") and clients engaging our web development services. This privacy policy applies to the Site and all products and services offered by Eclipse Pixel.</p>
                            <h3>2. Information We Collect</h3>
                            <p>a. Personal Information: We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, fill out a form, and in connection with other activities, services, features, or resources we make available on our Site.</p>
                            <p>Users may be asked for, as appropriate, name, email address, and phone number. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us.</p>
                            <p>b. Client Business Information: During the web development process, Eclipse Pixel may collect additional information about a client's business, including but not limited to, industry, target audience, goals, and objectives. This information is collected through direct communication channels such as email, phone calls, text messages, or in-person meetings.</p>
                            <h3>3. Purpose of Collection</h3>
                            <p>We collect personal information from Users for the purpose of responding to inquiries, processing transactions, and providing a personalized experience. Client business information is collected to tailor our web development services to the unique needs and objectives of each client.</p>
                            <h3>4. Information Collection Methods</h3>
                            <p>The personal information collected from Users is primarily obtained through the use of our website contact form. Additional details about a client's business are gathered through direct communication channels such as email, phone calls, text messages, or in-person meetings.</p>
                            <h3>5. Data Storage and Security</h3>
                            <p>a. Storage: User data is stored on both a local disk and in a secure cloud database.</p>
                            <p>b. Security Measures: Cloud data is encrypted using industry-standard protocols. We employ access controls, secure endpoints, intrusion detection and prevention systems, and regular security audits to protect user information.</p>
                            <h3>6. Data Sharing</h3>
                            <p>Eclipse Pixel does not sell, trade, or rent Users' personal identification information to others. We do not share client information with any third parties with the exception of cooperation with law enforcement agencies where legally required.</p>
                            <h3>7. User Rights</h3>
                            <p>a. Deletion: Users have the right to request the deletion of their information from both the cloud storage and local disk.</p>
                            <p>b. Opt-out: Users can request not to receive contacts from any representative of Eclipse Pixel.</p>
                            <h3>8. Security Measures in Case of Breach</h3>
                            <p>In the unlikely event of a data breach, Eclipse Pixel will take all reasonable measures to limit the amount of information stolen and promptly inform affected clients.</p>
                            <h3>9. Policy Updates</h3>
                            <p>This privacy policy may be updated periodically, and the date of the latest update will be indicated at the top of the policy. Clients will be notified of any significant changes via email.</p>
                            <h3>10. Legal Compliance</h3>
                            <p>Eclipse Pixel, a Texas-based web development studio, is committed to complying with applicable data protection laws and regulations.</p>
                            <h3>11. Contact Information</h3>
                            <p>For questions or concerns regarding this privacy policy, Users and clients can contact us at [your contact email/phone number].</p>
                        </div>
                    </div>
                </div>
            );
        
        case 'termsofservice':
            return(
                <div className='legal'>
                    <div className='legal-wrapper'>
                        <button onClick={() => close()}>X</button>
                        <h1>Terms of Service</h1>
                        <div className='legal-content'>
                            <h3>1. Introduction</h3>
                            <p>Welcome to Eclipse Pixel! These Terms of Service ("Terms") govern the use of our web development services. By accessing our website (eclipsepixel.com) or engaging our services, you agree to abide by these Terms.</p>
                            <h3>2. Services Offered</h3>
                            <p>Eclipse Pixel provides comprehensive web development services, covering all aspects from initial consultation to project delivery.</p>
                            <h3>3. User Conduct</h3>
                            <p>Users are expected to maintain respectful and professional behavior when interacting with any representative of Eclipse Pixel. Any instances of abuse or harassment will be escalated to an executive member for appropriate action.</p>
                            <h3>4. Ownership of Content</h3>
                            <p>Until the project is complete and delivered to the client, Eclipse Pixel retains ownership of any content related to the client's project, excluding images, videos, audio, or any other media provided by the client.</p>
                            <h3>5. Payments and Refunds</h3>
                            <p>a. Upfront Development Fee: A non-refundable upfront development fee of 40% of the total project cost is required before any work begins.</p>
                            <p>b. Final Payment: The remaining 60% must be paid within 1 week of project delivery and is also non-refundable.</p>
                            <h3>6. Project Cancellation and Copyright Compliance</h3>
                            <p>a. Criminal Activity: If a user shows clear signs of intent to conduct criminal activity using a project developed by Eclipse Pixel, the project will be cancelled without a refund of the deposit.</p>
                            <p>b. Copyright Compliance: If the user shares copyrighted content without proper licensing, the development process will be postponed until the proper licenses are obtained. If, after 1 week, the user fails to provide licensing information, the project will be cancelled with no refund of the deposit.</p>
                            <h3>7. Limitations and Legal Advice</h3>
                            <p>a. Data Accuracy: Eclipse Pixel provides data as accurately as it is provided to us. We are not legal advisors and cannot provide assistance with legal processes related to the client's project.</p>
                            <h3>8. Responsibility for Marketing Content</h3>
                            <p>Eclipse Pixel is not responsible for obscure, vague, or false marketing of products or services on the client's site. The client provides all information directly and is responsible for ensuring accuracy. Eclipse Pixel is not liable for any financial damages owed by the client's site in reparations for copyright lawsuits. All media sourced by Eclipse Pixel is correctly licensed.</p>
                            <h3>9. Changes to Terms of Service</h3>
                            <p>These Terms of Service may be updated periodically. Users will be notified of any changes, and continued use of the services implies acceptance of the updated Terms.</p>
                            <h3>10. Contact Information</h3>
                            <p>For questions or concerns regarding these Terms of Service, users can contact us at [your contact email/phone number].</p>
                        </div>
                    </div>
                </div>
            );
        
        case 'hidden':
            return(
                <></>
            );
    }
}

export default Legal;