const Loading = () => {
    return(
        <div className='loading'>
            <div className='loading-anim'>
                <h1 id='load-title-1'>ECLIPSEPIXEL</h1>
                <h1 id='load-title-2'>ECLIPSEPIXEL</h1>
            </div>
        </div>
    );
}

export default Loading;