import { useEffect, useState } from 'react';
import './App.css';
import Nav from './components/Nav';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Services from './components/Services';
import Gallery from './components/Gallery';
import Cursor from './components/Cursor';
import MobileNav from './components/MobileNav';
import Legal from './components/Legal';
import PageUnavailable from './components/Unavailable';

function App() {
  const [page, setPage] = useState('home');
  const [anim, setAnim] = useState(null);
  const [cursor, setCursor] = useState(null);
  const [legal, setLegal] = useState('hidden');

  const handleCursor = (message) => {
    setCursor(message);
  }

  const changePage = (pg) => {
    if(pg !== page){
      anim.style.transform= 'translateX(100px)';
      anim.style.opacity = '0';
      setTimeout(function(){
        setPage(pg);
        anim.style.transition = '0s';
        anim.style.transform = 'translateX(-100px)';
        setTimeout(function(){
          anim.style.transition = '0.5s';
          anim.style.transform = 'translateX(0px)';
          anim.style.opacity = '1';
        }, 10)
      }, 500);
    }
  }

  const currentPage = () => {
    switch(page){
      case 'home':
        return(
          <Home cursorMsg={handleCursor}/>
        );
      case 'about':
        return(
          <About cursorMsg={handleCursor}/>
        );
      case 'services':
        return(
          <Services cursorMsg={handleCursor}/>
        );
      case 'gallery':
        return(
          <PageUnavailable/>
        );
      case 'contact':
        return(
          <Contact/>
        );
    }
  }

  useEffect(() => {
    setAnim(document.getElementsByClassName('anim-transition')[0]);
  }, []);

  return (
    <div className="app">
      <Nav setPage={(pg) => changePage(pg)} />
      <MobileNav setPage={(pg) => changePage(pg)}/>
      <Cursor info={cursor}/>
      <div className='anim-transition'>
        {currentPage()}
      </div>
      <Legal type={legal} close={() => setLegal('hidden')}/>
      <div className='legal-btns'>
        <button onClick={() => setLegal('privacypolicy')}>Privacy Policy</button>
        <button onClick={() => setLegal('termsofservice')}>Terms of Service</button>
      </div>
    </div>
  );
}

export default App;
