import { useEffect, useState } from "react";

const Services = ({ cursorMsg }) => {
    const publicUrl = process.env.PUBLIC_URL;

    const [active, setActive] = useState(null);
    const [items, setItems] = useState(null);
    const [content, setContent] = useState(null);

    useEffect(() => {
        let i = document.getElementsByClassName('service-item');
        let c = document.getElementsByClassName('service-item-content');
        console.log(i);
        setItems(i);
        setContent(c);
    }, []);

    const changeActive = (i) => {
        if(i !== active){
            try{
                items[active].classList.remove('s-active');
                content[active].classList.add('hidden');
            }
            catch(e){
                console.log(e.message);
            }
            setActive(i);
            items[i].classList.add('s-active');
            content[i].classList.remove('hidden');
        }
    }

    const updateCursor = (e, i, enter) => {
        if(e !== null){
            console.log(e.target);
        }
        let cursorIndex = i - 1;
        const msgArr = [
            null,
            'Click to learn more about our design services',
            'Click to learn more about our development services',
            'Click to learn more about our IT services',
            'Geisha impress with refined artistry, mastering traditional Japanese arts and embodying cultural grace.'
        ]
        
        if(enter){
            if(active !== cursorIndex){
                cursorMsg(msgArr[i]);
            }
            else{
                cursorMsg(null);
            }
        }
        else{
            cursorMsg(null);
        }

    }

    return(
        <div className="page-section services">
            <div className='bg-cont'>
                <div id='blue-box'/>
                <img id='geisha' src={publicUrl+'/geisha.jpg'}onMouseEnter={(e) => updateCursor(e, 4, true)} onMouseLeave={() => updateCursor(null, 4, false)} alt='geisha'/>
            </div>
            <div className='service-list'>
                <div className='service-item'>
                    <h1 className='title-btn' onClick={() => changeActive(0)} onMouseEnter={(e) => updateCursor(e, 1, true)} onMouseLeave={() => updateCursor(null, 1, false)}>DESIGN</h1>
                    <div className='service-item-content hidden'>
                        <img src={publicUrl+'/design.jpg'} alt='design-image'/>
                        <div className='grid-container'>
                            <div className='grid-tile'>
                                <h1>Web Design</h1>
                                <p>Elevate your online presence with our cutting-edge web design services. We create visually stunning and user-friendly websites that not only captivate your audience but also enhance the overall user experience.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>Graphics Design</h1>
                                <p>Our graphic design expertise extends across various mediums, from digital to print. Whether you need eye-catching social media graphics, engaging marketing materials, or visually appealing presentations, we've got you covered.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>Logo Design</h1>
                                <p>A logo is the face of your brand. Our skilled designers will work closely with you to develop a unique and memorable logo that reflects your brand identity and resonates with your target audience.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>Branding</h1>
                                <p>Building a strong brand is essential for success. We offer comprehensive branding services, including brand strategy, logo design, and brand guidelines, to ensure consistency and authenticity in every interaction with your audience.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>Print Media</h1>
                                <p>From brochures and flyers to posters and banners, our print media design services are aimed at creating impactful and visually appealing materials that leave a lasting impression in the physical world.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>Business Cards</h1>
                                <p>Make a memorable first impression with professionally designed business cards. Our designs not only convey your contact information but also reflect the essence of your brand.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='service-item'>
                    <h1 className='title-btn' onClick={() => changeActive(1)} onMouseEnter={(e) => updateCursor(e, 2, true)} onMouseLeave={() => updateCursor(null, 2, false)}>DEVELOPMENT</h1>
                    <div className='service-item-content hidden'>
                    <img src={publicUrl+'/development.jpg'} alt='design-image'/>
                        <div className='grid-container'>
                            <div className='grid-tile'>
                                <h1>Web Development</h1>
                                <p>Forge a powerful online presence with our expert web development services. Our team of skilled developers is dedicated to creating responsive and user-friendly websites that not only meet your business objectives but also provide an immersive experience for your audience.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>CRM Integration</h1>
                                <p>Optimize your business processes with our CRM integration services. We seamlessly integrate customer relationship management systems, empowering you to manage leads, automate workflows, and enhance customer interactions for improved efficiency and growth.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>App Development</h1>
                                <p>Step into the mobile era with our custom app development services. Whether you need a feature-rich mobile app for iOS or Android, we leverage the latest technologies to bring your ideas to life, ensuring a seamless and engaging user experience.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>E-Commerce Solutions</h1>
                                <p>Transform your business into a digital storefront with our e-commerce development services. We design and develop secure and scalable e-commerce platforms that not only showcase your products but also provide a frictionless shopping experience for your customers.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>API Development</h1>
                                <p>Enhance connectivity and streamline data exchange with our API development services. We build robust and scalable APIs tailored to your specific needs, facilitating seamless integration between different software systems and applications.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>Cloud Solutions</h1>
                                <p>Unlock the power of the cloud with our customized cloud solutions. From cloud infrastructure setup to migration and optimization, we ensure your business operates efficiently and securely in the digital cloud environment.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='service-item'>
                    <h1 className='title-btn' onClick={() => changeActive(2)} onMouseEnter={(e) => updateCursor(e, 3, true)} onMouseLeave={() => updateCursor(null, 3, false)}>IT SERVICES</h1>
                    <div className='service-item-content hidden'>
                    <img src={publicUrl+'/it-solutions.jpg'} alt='design-image'/>
                        <div className='grid-container'>
                            <div className='grid-tile'>
                                <h1>Managed IT Support</h1>
                                <p>Ensure your business operates smoothly with our expert managed IT support services. Our team provides proactive monitoring, troubleshooting, and resolution, ensuring your systems are always up and running.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>Web Hosting</h1>
                                <p>Experience reliable and secure web hosting solutions tailored to your business needs. Our hosting services ensure your website is accessible, fast, and scalable, providing a solid foundation for your online presence.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>Network Security</h1>
                                <p>Protect your valuable data from cyber threats with our robust network security solutions. From firewalls and antivirus measures to intrusion detection, we employ the latest technologies to safeguard your digital assets.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>IT Consultancy</h1>
                                <p>Navigate the complex world of IT with our expert consultancy services. Whether you're planning an IT strategy, evaluating technology investments, or seeking guidance on digital transformation, our consultants are here to provide valuable insights tailored to your business needs.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>IT Infrastruction Management</h1>
                                <p>Optimize your IT infrastructure for peak performance with our infrastructure management services. From server maintenance to software updates, we ensure your technology backbone is robust, scalable, and aligned with your business objectives.</p>
                            </div>
                            <div className='grid-tile'>
                                <h1>Custom Solutions</h1>
                                <p>Recognizing that each business is unique, we offer customized IT solutions to address your specific challenges and goals. Our team collaborates with you to design tailor-made solutions that enhance efficiency, reduce costs, and drive innovation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;