const CircleAnim = () => {
    const publicUrl = process.env.PUBLIC_URL;
    const gfxUrl = [
        publicUrl+'/anim/circle1.png',
        publicUrl+'/anim/circle2.png',
        publicUrl+'/anim/circle3.png',
        publicUrl+'/anim/circle4.png',
        publicUrl+'/anim/circle5.png',
        publicUrl+'/anim/circle6.png',
        publicUrl+'/anim/circle7.png',
    ]
    return(
        <div className='circle-anim'>
            <img src={gfxUrl[0]} alt='circle' style={{ animationDuration: '10s' }}/>
            <img src={gfxUrl[1]} alt='circle' style={{ animationDuration: '12s' }}/>
            <img src={gfxUrl[2]} alt='circle' style={{ animationDuration: '8s' }}/>
            <img src={gfxUrl[3]} alt='circle' style={{ animationDuration: '9s' }}/>
            <img src={gfxUrl[4]} alt='circle' style={{ animationDuration: '12s' }}/>
            <img src={gfxUrl[5]} alt='circle' style={{ animationDuration: '10s' }}/>
            <img src={gfxUrl[6]} alt='circle' style={{ animationDuration: '7s' }}/>
        </div>
    );

}

export default CircleAnim;