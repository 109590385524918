const PageUnavailable = () => {
    return(
        <div className='page-section unavailable'>
            <div className='notice-wrapper'>
                <h4>Page Unavailable</h4>
                <p>We apologise for the inconvenience and thank you for your patience.  This page is currently under development and will be available as soon as possible.</p>
            </div>
        </div>
    )
}

export default PageUnavailable;