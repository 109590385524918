import { analytics } from "../Firebase";
import { logEvent } from "firebase/analytics";

const About = ({ cursorMsg }) => {
    const publicUrl = process.env.PUBLIC_URL;

    const updateCursor = (e, i, enter) => {
        const msgArr = [
            null,
            'Sushi chefs embody artistry and dedication, meticulously crafting each dish to perfection, showcasing a profound commitment to their craft.'
        ]
        
        if(enter){
            cursorMsg(msgArr[i]);
            logEvent(analytics, 'cursor_guide', {
                hovered: 'manekineko'
            });
        }
        else{
            cursorMsg(null);
        }

    }

    return(
        <div className='page-section about'>
            <h1>ECLIPSEPIXEL</h1>
            <div className="ab-img" style={{backgroundImage: 'url('+publicUrl+'/temple.jpg)'}}/>
            <img src={publicUrl+'/sushi.png'}onMouseEnter={(e) => updateCursor(e, 1, true)} onMouseLeave={() => updateCursor(null, 1, false)} id='sushi'/>
            <div className='about-text-block'>
                <img src={publicUrl+'/aboutjp.png'} id='aboutjp'/>
                <p>EclipsePixel is a small team of developers with the talent and skill to develop beautiful and performant web solutions.  Our goal is to make these professional services more available to everyone.  Sure there are website builders out there but in the long run you end up paying more for less.  Besides, there’s nothing quite like having a tailor-made site.</p>
                <p>With our goal of availability in mind, affordability is one of the major factors.  To keep costs down for you while keeping quality high, our team utilize a variety of technologies to accelerate our workflow.  What this means for you is not only do you end up with a more polished site in less time than it would traditionally take, but also at a better price.  Rest assured we are not cutting any corners.  Our pride is our work!</p>
            </div>
        </div>
    )
}

export default About;